<template>
  <bar-chart
    class="mb-6"
    :height="400"
    :options="chartOptions"
    :chart-data="chartData"
  />
</template>

<script>
import {
  computed, defineComponent, ref,
} from '@vue/composition-api'
import { BarChart } from 'vue-chart-3'

export default defineComponent({
  name: 'MonthlyEnergyChart',
  components: {
    BarChart,
  },
  setup() {
    const fuelSaving = ref([])
    const solar = ref([])
    const load = ref([])
    const generator = ref([])

    const chartData = computed(() => ({
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Load (kWh)',
          data: load.value,
          fill: false,
          borderColor: 'rgb(0, 174, 239)',
          backgroundColor: 'rgb(0, 174, 239)',
          yAxisID: 'ykWh',
          order: 4,
        },
        {
          label: 'Solar (kWh)',
          data: solar.value,
          fill: false,
          borderColor: 'rgb(0, 180, 156)',
          backgroundColor: 'rgb(0, 180, 156)',
          yAxisID: 'ykWh',
          order: 2,
        },
        {
          label: 'Generator (kWh)',
          data: generator.value,
          fill: false,
          borderColor: 'rgb(218, 28, 92)',
          backgroundColor: 'rgb(218, 28, 92)',
          yAxisID: 'ykWh',
          order: 3,
        },
        {
          type: 'line',
          label: 'Fuel Saving (%)',
          data: fuelSaving.value,
          borderColor: 'rgb(72, 47, 146)',
          backgroundColor: 'rgb(72, 47, 146)',
          yAxisID: 'yPercent',
          order: 1,
        },
      ],
    }))
    const chartOptions = computed(() => ({
      scales: {
        ykWh: {
          title: {
            display: true,
            text: 'kWh',
          },
        },
        yPercent: {
          position: 'right',
          title: {
            display: true,
            text: 'Fuel Saving (%)',
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: 'Monthly Energy Consumption/Generation',
        },
      },
    }))

    const resetData = () => {
      load.value = []
      solar.value = []
      generator.value = []
      fuelSaving.value = []
    }

    const updateData = data => {
      resetData()
      load.value = data.load
      solar.value = data.solar
      generator.value = data.generator
      fuelSaving.value = data.fuelSaving
    }

    return {
      chartData,
      chartOptions,

      updateData,
      resetData,
    }
  },
})
</script>
