<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Alarm
        </v-card-title>
        <div>
          <v-data-table
            :headers="headers"
            :items="alarmList"
            :items-per-page="5"
            :loading="tableLoading"
          >
            <template #[`item.status`]="{ item }">
              <span v-html="statusToView(item.status)"></span>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    siteId: String,
    siteInfo: Object,
  },
  setup(props) {
    const tableLoading = ref(true)

    const statusToView = status => {
      if (Object.keys(status).length <= 0) {
        return 'Normal'
      }

      let result = ''
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(status)) {
        result += `${key}: ${value}<br>`
      }

      return result
    }
    const alarmList = computed(() => {
      const result = []
      store.state.alarms.list.forEach(obj => {
        const status = Object.fromEntries(Object.entries(obj).filter(([key, val]) => key.includes('ALI') && val.toString().toLowerCase() !== 'normal'))
        result.push({
          deviceId: obj.deviceId,
          device_name_nixt: obj.device_name_nixt,
          status,
          lastDataAt: obj.lastDataAt,
        })
      })

      return result
    })

    const loadSiteList = () => {
      tableLoading.value = true
      store.dispatch('alarms/fetchAlarms', { siteId: props.siteId })
        .then(() => {
          tableLoading.value = false
        })
    }

    watch(() => props.siteId, () => {
      loadSiteList()
    })

    onMounted(() => {
      loadSiteList()
    })

    return {
      headers: [
        { text: 'NAME', value: 'device_name_nixt' },
        { text: 'STATUS', value: 'status' },
        { text: 'DATA AT', value: 'lastDataAt', align: 'center' },
      ],
      alarmList,
      tableLoading,
      statusToView,
    }
  },
}
</script>
