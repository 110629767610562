<template>
  <doughnut-chart
    class="mb-6"
    :height="400"
    :chart-data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { Chart } from 'chart.js'
import { DoughnutChart } from 'vue-chart-3'
import doughnutLabel from '@/plugins/chartjs-plugin-doughnutlabel'
import 'chartjs-adapter-dayjs'

Chart.register(doughnutLabel)

export default defineComponent({
  name: 'SavingChart',
  components: {
    DoughnutChart,
  },
  props: {
    saving: Number,
    consumption: Number,
  },
  setup(props) {
    const chartData = computed(() => ({
      labels: ['Consumption', 'Saving'],
      datasets: [
        {
          data: [props.saving.toFixed(2), props.consumption.toFixed(2)],
          backgroundColor: ['rgb(245, 215, 222)', 'rgb(201, 53, 93)'],
          borderWidth: 0,
        },
      ],
    }))
    const chartOptions = computed(() => ({
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          callbacks: {
            label(context) {
              return `${context.label} : ${context.raw} %`
            },
          },
        },
        doughnutlabel: {
          text: `Saving\n${props.saving.toFixed(2)}%`,
          color: 'rgb(201, 53, 93)',
        },
      },
    }))

    return {
      chartData,
      chartOptions,
    }
  },
})
</script>
