<template>
  <div>
    <div class="my-5">
      <v-btn small color="secondary" @click.stop="isDialogVisible = true" :loading="loadingLastProcess">Configuration</v-btn>
    </div>
    <v-row class="row-chart">
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="3" class="d-flex align-center">
                <saving-chart :saving="resultData.saving_soc.avg_saving" :consumption="resultData.saving_soc.avg_consumption" />
              </v-col>
              <v-col cols="12" lg="9">
                <monthly-energy-chart ref="chartMonthlyEnergy" />
              </v-col>
              <v-col cols="12">
                <monthly-fuel-chart ref="chartMonthlyFuel" />
              </v-col>
              <v-col cols="12">
                <pivotk-w-chart ref="chartPivotSoc" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="isDialogVisible"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuration</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
            v-if="queryError !== null"
            type="error"
            dense
            dismissible
          >
            {{ queryError }}
          </v-alert>
          <v-form ref="queryForm">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-file-input
                    v-model="queryConfig.file_solar"
                    accept=".csv"
                    label="Solar Profile"
                    :rules="ruleFile"
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    v-model="queryConfig.file_load"
                    accept=".csv"
                    label="Load Profile"
                    :rules="ruleFile"
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="queryConfig.Data_Interval"
                    label="Data Interval (Sec)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="queryConfig.Correction_Factor"
                    label="Correct Factor"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Initial_SOC"
                    label="Initial SOC (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Battery_Cut_off"
                    label="Battery Cut off (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                    v-model="queryConfig.Gen_charge_Batt"
                    inset
                    color="primary"
                    label="Gen charge Batt"
                    hide-details
                    class="mt-2"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Battery_Capacity"
                    label="Battery Capacity (kWh)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Inverter_Power"
                    label="Inverter Power (kW)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Discharging_Efficiency"
                    label="Discharging Efficiency (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Charging_Efficiency"
                    label="Charging Efficiency (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-switch
                    v-model="queryConfig.Gen_by_SOC"
                    inset
                    color="primary"
                    label="Gen Control by SOC"
                    hide-details
                    class="mt-2"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Gen_ON_at_SOC"
                    label="Gen ON at SOC (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Gen_OFF_at_SOC"
                    label="Gen Off at SOC (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Gen_Power"
                    label="Generator Power (kW)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.OC_High_Factor"
                    label="OC Rate High (100%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.OC_Mid_Factor"
                    label="OC Rate Mid (80%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.OC_Low_Factor"
                    label="OC Rate Low (60%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-switch
                    v-model="queryConfig.Gen_by_Time"
                    inset
                    color="primary"
                    label="Gen Control by Time"
                    hide-details
                    class="mt-2"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Gen_ON_at_Time"
                    label="Gen ON at Time"
                    dense
                    outlined
                    hide-details
                    type="time"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Gen_OFF_at_Time"
                    label="Gen Off at Time"
                    dense
                    outlined
                    hide-details
                    type="time"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-switch
                    v-model="queryConfig.Grid_Enable"
                    inset
                    color="primary"
                    label="Grid Enable"
                    hide-details
                    class="mt-2"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Grid_Power_On_Peak"
                    label="Grid Power On Peak (kW)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Grid_Power_Off_Peak"
                    label="Grid Power Off Peak (kW)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.On_Peak_Price"
                    label="On Peak Price (B/kWh)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.On_Peak_Start"
                    label="On Peak Start"
                    dense
                    outlined
                    hide-details
                    type="time"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Off_Peak_Price"
                    label="Off Peak Price (B/kWh)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.On_Peak_End"
                    label="On Peak End"
                    dense
                    outlined
                    hide-details
                    type="time"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Grid_ON_at_SOC"
                    label="Grid ON at SOC (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Grid_OFF_at_SOC"
                    label="Grid Off at SOC (%)"
                    dense
                    outlined
                    hide-details
                    type="number"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                    v-model="queryConfig.Grid_Control_by_Time"
                    inset
                    color="primary"
                    label="Grid Control by Time"
                    hide-details
                    class="mt-2"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Grid_Charge_Start"
                    label="Grid Charge Start"
                    dense
                    outlined
                    hide-details
                    type="time"
                    :rules="[required]"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="queryConfig.Grid_Charge_End"
                    label="Grid Charge End"
                    dense
                    outlined
                    hide-details
                    type="time"
                    :rules="[required]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="error"
            outlined
            :disabled="dataLoading"
            @click.stop="isDialogVisible = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <span class="me-4 error--text" v-if="validateFailed">Please complete form</span>
          <span class="me-4 error--text" v-else-if="queryError !== null">{{ queryError }}</span>
          <v-btn
            color="primary"
            :loading="dataLoading"
            @click="queryData"
          >
            Process
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref, reactive, watch,
} from '@vue/composition-api'

import axios from '@axios'
import { mdiCalendar } from '@mdi/js'
import { required } from '@core/utils/validation'
import SavingChart from './SavingChart.vue'
import MonthlyEnergyChart from './MonthlyEnergyChart.vue'
import MonthlyFuelChart from './MonthlyFuelChart.vue'
import PivotkWChart from './PivotkWChart.vue'

export default {
  components: {
    MonthlyEnergyChart,
    MonthlyFuelChart,
    PivotkWChart,
    SavingChart,
  },
  props: {
    siteId: String,
    siteInfo: Object,
  },
  setup(props) {
    const queryForm = ref(null)
    const isDialogVisible = ref(false)
    const validateFailed = ref(false)
    const notNull = value => (value !== null ? true : 'This field is required')
    const ruleFile = ref([notNull])
    const queryConfig = reactive({
      lastProcess: false,
      file_solar: null,
      file_load: null,
      Data_Interval: '300',
      Correction_Factor: '1',
      Initial_SOC: '50',
      Battery_Cut_off: '98',
      Gen_charge_Batt: true,
      Battery_Capacity: '256',
      Inverter_Power: '250',
      Discharging_Efficiency: '96',
      Charging_Efficiency: '96',
      Gen_by_SOC: true,
      Gen_ON_at_SOC: '25',
      Gen_OFF_at_SOC: '90',
      OC_Low_Factor: '1.7',
      OC_Mid_Factor: '1.4',
      OC_High_Factor: '1.2',
      Gen_by_Time: false,
      Gen_ON_at_Time: '18:00:00',
      Gen_OFF_at_Time: '19:00:00',
      Grid_Enable: false,
      Grid_Power_On_Peak: '100',
      Grid_Power_Off_Peak: '100',
      On_Peak_Price: '5.27',
      On_Peak_Start: '09:00:00',
      Off_Peak_Price: '2.18',
      On_Peak_End: '22:00:00',
      Grid_ON_at_SOC: '30',
      Grid_OFF_at_SOC: '90',
      Grid_Control_by_Time: false,
      Grid_Charge_Start: '21:00:00',
      Grid_Charge_End: '23:55:00',
      Gen_Power: '240',
    })
    const dataLoading = ref(false)
    const queryError = ref(null)
    const months = {
      JAN: 0,
      FEB: 1,
      MAR: 2,
      APR: 3,
      MAY: 4,
      JUN: 5,
      JUL: 6,
      AUG: 7,
      SEP: 8,
      OCT: 9,
      NOV: 10,
      DEC: 11,
    }
    const resultData = reactive({
      saving_soc: {
        avg_consumption: 100,
        avg_saving: 0,
      },
      fuelSaving: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      solarkWh: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      loadkWh: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      genkWh: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fuelWithSystem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      customerEstimation: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      pivotSoc: [],
    })
    const chartMonthlyEnergy = ref(null)
    const chartMonthlyFuel = ref(null)
    const chartPivotSoc = ref(null)
    const updateCharts = () => {
      chartMonthlyEnergy.value.updateData({
        fuelSaving: resultData.fuelSaving,
        load: resultData.loadkWh,
        solar: resultData.solarkWh,
        generator: resultData.genkWh,
      })
      chartMonthlyFuel.value.updateData({
        customerEstimation: resultData.customerEstimation,
        fuelWithSystem: resultData.fuelWithSystem,
        fuelSaving: resultData.fuelSaving,
      })
      chartPivotSoc.value.updateData(resultData.pivotSoc)
    }
    const queryData = () => {
      validateFailed.value = false
      queryError.value = null
      if (queryForm.value.validate()) {
        dataLoading.value = true
        const formdata = new FormData()
        if (ruleFile.value.length > 0) {
          formdata.append('file_solar', queryConfig.file_solar)
          formdata.append('file_load', queryConfig.file_load)
        }

        formdata.append('site_id', props.siteId)
        formdata.append('Data_Interval', queryConfig.Data_Interval)
        formdata.append('Correction_Factor', queryConfig.Correction_Factor)
        formdata.append('Initial_SOC', queryConfig.Initial_SOC / 100)
        formdata.append('Battery_Cut_off', queryConfig.Battery_Cut_off / 100)
        formdata.append('Gen_charge_Batt', queryConfig.Gen_charge_Batt)
        formdata.append('Battery_Capacity', queryConfig.Battery_Capacity)
        formdata.append('Inverter_Power', queryConfig.Inverter_Power)
        formdata.append('Discharging_Efficiency', queryConfig.Discharging_Efficiency / 100)
        formdata.append('Charging_Efficiency', queryConfig.Charging_Efficiency / 100)
        formdata.append('Gen_by_SOC', queryConfig.Gen_by_SOC === true ? 'true' : 'false')
        formdata.append('Gen_ON_at_SOC', queryConfig.Gen_ON_at_SOC / 100)
        formdata.append('Gen_OFF_at_SOC', queryConfig.Gen_OFF_at_SOC / 100)
        formdata.append('OC_Low_Factor', queryConfig.OC_Low_Factor)
        formdata.append('OC_Mid_Factor', queryConfig.OC_Mid_Factor)
        formdata.append('OC_High_Factor', queryConfig.OC_High_Factor)
        formdata.append('Gen_by_Time', queryConfig.Gen_by_Time === true ? 'true' : 'false')
        formdata.append('Gen_ON_at_Time', queryConfig.Gen_ON_at_Time)
        formdata.append('Gen_OFF_at_Time', queryConfig.Gen_OFF_at_Time)
        formdata.append('Grid_Enable', queryConfig.Grid_Enable === true ? 'true' : 'false')
        formdata.append('Grid_Power_On_Peak', queryConfig.Grid_Power_On_Peak)
        formdata.append('Grid_Power_Off_Peak', queryConfig.Grid_Power_Off_Peak)
        formdata.append('On_Peak_Price', queryConfig.On_Peak_Price)
        formdata.append('On_Peak_Start', queryConfig.On_Peak_Start)
        formdata.append('Off_Peak_Price', queryConfig.Off_Peak_Price)
        formdata.append('On_Peak_End', queryConfig.On_Peak_End)
        formdata.append('Grid_ON_at_SOC', queryConfig.Grid_ON_at_SOC / 100)
        formdata.append('Grid_OFF_at_SOC', queryConfig.Grid_OFF_at_SOC / 100)
        formdata.append('Grid_Control_by_Time', queryConfig.Grid_Control_by_Time === true ? 'true' : 'false')
        formdata.append('Grid_Charge_Start', queryConfig.Grid_Charge_Start)
        formdata.append('Grid_Charge_End', queryConfig.Grid_Charge_End)
        formdata.append('Gen_Power', queryConfig.Gen_Power)

        axios
          .post('/simulator/process', formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resultData.saving_soc = response.data.saving_soc
            // eslint-disable-next-line camelcase
            const { pivot_grpah, pivot_soc } = response.data
            pivot_grpah.forEach(data => {
              const curMonthIdx = months[data.Month.toUpperCase()]
              resultData.fuelSaving[curMonthIdx] = data.Fuel_Saving
              resultData.solarkWh[curMonthIdx] = data.Solar_kWh
              resultData.loadkWh[curMonthIdx] = data.Load_kWh
              resultData.genkWh[curMonthIdx] = data.Gen_kWh
              resultData.fuelWithSystem[curMonthIdx] = data.Fuel_System_L
              resultData.customerEstimation[curMonthIdx] = data.Custom_Estimate
            })

            Object.entries(pivot_soc).forEach(([month, data]) => {
              resultData.pivotSoc[months[month.toUpperCase()]] = data
            })

            updateCharts()

            isDialogVisible.value = false
          })
          .catch(() => {
            queryError.value = 'Error while processing'
          })
          .finally(() => {
            dataLoading.value = false
          })
      } else {
        validateFailed.value = true
      }
    }

    const loadingLastProcess = ref(true)
    const getLastProcess = () => {
      loadingLastProcess.value = true
      axios
        .get(`/simulator/lastprocess?site_id=${props.siteId}`)
        .then(response => {
          /* eslint-disable camelcase */
          const {
            saving_soc, pivot_grpah, pivot_soc, configuration,
          } = response.data
          if (saving_soc) {
            resultData.saving_soc = saving_soc
          }
          if (pivot_grpah) {
            pivot_grpah.forEach(data => {
              const curMonthIdx = months[data.Month.toUpperCase()]
              resultData.fuelSaving[curMonthIdx] = data.Fuel_Saving
              resultData.solarkWh[curMonthIdx] = data.Solar_kWh
              resultData.loadkWh[curMonthIdx] = data.Load_kWh
              resultData.genkWh[curMonthIdx] = data.Gen_kWh
              resultData.fuelWithSystem[curMonthIdx] = data.Fuel_System_L
              resultData.customerEstimation[curMonthIdx] = data.Custom_Estimate
            })
          }
          if (pivot_soc) {
            Object.entries(pivot_soc).forEach(([month, data]) => {
              resultData.pivotSoc[months[month.toUpperCase()]] = data
            })
          }
          if (configuration) {
            queryConfig.lastProcess = true
            ruleFile.value = []
            queryConfig.Data_Interval = configuration.Data_Interval.toString() || '300'
            queryConfig.Correction_Factor = configuration.Correction_Factor.toString() || '1'
            queryConfig.Initial_SOC = (configuration.Initial_SOC * 100).toString() || '50'
            queryConfig.Battery_Cut_off = (configuration.Battery_Cut_off * 100).toString() || '98'
            queryConfig.Gen_charge_Batt = configuration.Gen_charge_Batt || true
            queryConfig.Battery_Capacity = configuration.Battery_Capacity.toString() || '256'
            queryConfig.Inverter_Power = configuration.Inverter_Power.toString() || '250'
            queryConfig.Discharging_Efficiency = (configuration.Discharging_Efficiency * 100).toString() || '96'
            queryConfig.Charging_Efficiency = (configuration.Charging_Efficiency * 100).toString() || '96'
            queryConfig.Gen_by_SOC = configuration.Gen_by_SOC || true
            queryConfig.Gen_ON_at_SOC = (configuration.Gen_ON_at_SOC * 100).toString() || '25'
            queryConfig.Gen_OFF_at_SOC = (configuration.Gen_OFF_at_SOC * 100).toString() || '90'
            queryConfig.OC_Low_Factor = configuration.OC_Low_Factor.toString() || '1.7'
            queryConfig.OC_Mid_Factor = configuration.OC_Mid_Factor.toString() || '1.4'
            queryConfig.OC_High_Factor = configuration.OC_High_Factor.toString() || '1.2'
            queryConfig.Gen_by_Time = configuration.Gen_by_Time || false
            queryConfig.Gen_ON_at_Time = configuration.Gen_ON_at_Time.toString() || '18:00:00'
            queryConfig.Gen_OFF_at_Time = configuration.Gen_OFF_at_Time.toString() || '19:00:00'
            queryConfig.Grid_Enable = configuration.Grid_Enable || false
            queryConfig.Grid_Power_On_Peak = configuration.Grid_Power_On_Peak.toString() || '100'
            queryConfig.Grid_Power_Off_Peak = configuration.Grid_Power_Off_Peak.toString() || '100'
            queryConfig.On_Peak_Price = configuration.On_Peak_Price.toString() || '5.27'
            queryConfig.On_Peak_Start = configuration.On_Peak_Start.toString() || '09:00:00'
            queryConfig.Off_Peak_Price = configuration.Off_Peak_Price.toString() || '2.18'
            queryConfig.On_Peak_End = configuration.On_Peak_End.toString() || '22:00:00'
            queryConfig.Grid_ON_at_SOC = (configuration.Grid_ON_at_SOC * 100).toString() || '30'
            queryConfig.Grid_OFF_at_SOC = (configuration.Grid_OFF_at_SOC * 100).toString() || '90'
            queryConfig.Grid_Control_by_Time = configuration.Grid_Control_by_Time || false
            queryConfig.Grid_Charge_Start = configuration.Grid_Charge_Start.toString() || '21:00:00'
            queryConfig.Grid_Charge_End = configuration.Grid_Charge_End.toString() || '23:55:00'
            queryConfig.Gen_Power = configuration.Gen_Power.toString() || '240'
            queryConfig.Battery_Capacity = configuration.Battery_Capacity.toString() || ''
          } else {
            queryConfig.lastProcess = false
            ruleFile.value = [notNull]
          }

          if (saving_soc || pivot_grpah || pivot_soc) {
            updateCharts()
          } else {
            isDialogVisible.value = true
          }
        })
        .finally(() => {
          loadingLastProcess.value = false
        })
    }
    getLastProcess()

    watch(queryConfig, newValue => {
      if (queryConfig.lastProcess === true) {
        // Required 2 files to be uploaded
        if ((newValue.file_solar !== null || newValue.file_solar !== null)) {
          ruleFile.value = [notNull]
        } else {
          ruleFile.value = []
        }
      }
    })
    watch(isDialogVisible, () => {
      if (isDialogVisible.value === false) {
        queryError.value = null
        queryForm.value.resetValidation()
      }
    })

    watch(() => props.siteId, () => {
      if (!isDialogVisible.value) {

        // isDialogVisible.value = true
        resultData.saving_soc = {
          avg_consumption: 100,
          avg_saving: 0,
        }
        resultData.fuelSaving = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        resultData.solarkWh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        resultData.loadkWh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        resultData.genkWh = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        resultData.fuelWithSystem = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        resultData.customerEstimation = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        resultData.pivotSoc = []

        chartMonthlyFuel.value.resetData()
        chartMonthlyEnergy.value.resetData()
        chartPivotSoc.value.resetData()

        getLastProcess()
      }
    })

    return {
      queryForm,
      isDialogVisible,
      queryError,
      queryConfig,
      dataLoading,
      resultData,
      validateFailed,
      loadingLastProcess,
      ruleFile,

      chartMonthlyEnergy,
      chartMonthlyFuel,
      chartPivotSoc,

      queryData,
      required,
      notNull,

      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
