<template>
  <bar-chart
    class="mb-6"
    :height="400"
    :options="chartOptions"
    :chart-data="chartData"
  />
</template>

<script>
import { ref, computed, defineComponent } from '@vue/composition-api'
import { BarChart } from 'vue-chart-3'

export default defineComponent({
  name: 'MonthlyFuelChart',
  components: {
    BarChart,
  },
  setup() {
    const fuelSaving = ref([])
    const fuelWithSystem = ref([])
    const customerEstimation = ref([])

    const chartData = computed(() => ({
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          type: 'line',
          label: 'Customer\'s Estimation (L)',
          data: customerEstimation.value,
          fill: true,
          borderColor: 'rgb(165, 165, 165)',
          backgroundColor: 'rgb(165, 165, 165)',
          pointRadius: 0,
          order: 3,
        },
        {
          label: 'Fuel with System (L)',
          data: fuelWithSystem.value,
          fill: false,
          borderColor: 'rgb(218, 28, 92)',
          backgroundColor: 'rgb(218, 28, 92)',
          order: 2,
          yAxisID: 'yLiter',
        },
        {
          type: 'line',
          label: 'Fuel Saving (%)',
          data: fuelSaving.value,
          borderColor: 'rgb(72, 47, 146)',
          backgroundColor: 'rgb(72, 47, 146)',
          order: 1,
          yAxisID: 'yFuelSaving',
        },
      ],
    }))
    const chartOptions = computed(() => ({
      scales: {
        yLiter: {
          title: {
            display: true,
            text: 'Liter',
          },
        },
        yFuelSaving: {
          position: 'right',
          title: {
            display: true,
            text: 'Fuel Saving (%)',
          },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
        title: {
          display: true,
          text: 'Monthly Fuel Consumption and Saving',
        },
      },
    }))

    const resetData = () => {
      fuelWithSystem.value = []
      fuelSaving.value = []
      customerEstimation.value = []

      // chartData.datasets[0].data = [0]
      // chartData.datasets[1].data = []
      // chartData.datasets[2].data = []
    }

    const updateData = data => {
      resetData()

      fuelWithSystem.value = data.fuelWithSystem
      fuelSaving.value = data.fuelSaving
      customerEstimation.value = data.customerEstimation

      // chartData.datasets[0].data = data.customerEstimation
      // chartData.datasets[1].data = data.fuelWithSystem
      // chartData.datasets[2].data = data.fuelSaving
    }

    return {
      chartData,
      chartOptions,

      updateData,
      resetData,
    }
  },
})
</script>
