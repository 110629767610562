const doughnutlabel = {
  id: 'doughnutlabel',

  beforeDraw(chart, args, opts) {
    if (
      typeof chart.config.options.plugins.doughnutlabel !== 'undefined' &&
      typeof chart.config.options.plugins.doughnutlabel.text !== 'undefined'
    ) {
      const { width, height, ctx } = chart

      ctx.restore()
      const fontSize = (height / 150).toFixed(2)
      ctx.font = `${fontSize}rem Gotham`
      ctx.textBaseline = 'middle'

      const options = chart.config.options.plugins.doughnutlabel
      const { text } = options
      if (typeof options.color !== 'undefined') {
        ctx.fillStyle = options.color
      }

      const lineheight = 40
      const lines = text.split('\n')
      const textY = height / lines.length - 20
      let textX = ''
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < lines.length; i++) {
        textX = Math.round((width - ctx.measureText(lines[i]).width) / 2)
        ctx.fillText(lines[i], textX, textY + i * lineheight)
      }
      ctx.save()
    }
  },

  // beforeDatasetDraw: function (chart, args, opts) {
  //   if (
  //     typeof chart.config.options.plugins.doughnutlabel !== 'undefined' &&
  //     chart.config.options.plugins.doughnutlabel.labels.length > 0
  //   ) {
  //     const { width, height, ctx } = chart
  //     const options = chart.config.options.plugins.doughnutlabel
  //
  //     var innerLabels = []
  //     options.labels.forEach(label => {
  //       var text = typeof label.text === 'function' ? label.text(chart) : label.text
  //       var innerLabel = {
  //         text,
  //         font: 'Gotham',
  //         color: '#000',
  //       }
  //       innerLabels.push(innerLabel)
  //     })
  //     var textAreaSize = utils.textSize(ctx, innerLabels)
  //
  //     // Calculate the adjustment ratio to fit the text area into the doughnut inner circle
  //     var hypotenuse = Math.sqrt(Math.pow(textAreaSize.width, 2) + Math.pow(textAreaSize.height, 2))
  //     var innerDiameter = chart.innerRadius * 2
  //     var fitRatio = innerDiameter / hypotenuse
  //
  //     // Adjust the font if necessary and recalculate the text area after applying the fit ratio
  //     if (fitRatio < 1) {
  //       innerLabels.forEach(function (innerLabel) {
  //         innerLabel.font.size = Math.floor(innerLabel.font.size * fitRatio)
  //         innerLabel.font.lineHeight = 12
  //         innerLabel.font = 'Gotham'
  //       })
  //
  //       textAreaSize = utils.textSize(ctx, innerLabels)
  //     }
  //     console.log(innerLabels)
  //     console.log('111', textAreaSize)
  //     ctx.textAlign = 'center'
  //     ctx.textBaseline = 'middle'
  //
  //     // The center of the inner circle
  //     var centerX = (chart.chartArea.left + chart.chartArea.right) / 2
  //     var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
  //
  //     // The top Y coordinate of the text area
  //     var topY = centerY - textAreaSize.height / 2
  //
  //     var i
  //     var ilen = innerLabels.length
  //     var currentHeight = 0
  //     for (i = 0; i < ilen; ++i) {
  //       ctx.fillStyle = innerLabels[i].color
  //       ctx.font = innerLabels[i].font.string
  //
  //       // The Y center of each line
  //       var lineCenterY = topY + innerLabels[i].font.lineHeight / 2 + currentHeight
  //       currentHeight += innerLabels[i].font.lineHeight
  //
  //       // Draw each line of text
  //       ctx.fillText(innerLabels[i].text, centerX, lineCenterY)
  //       console.log(innerLabels[i].text, centerX, lineCenterY)
  //     }
  //   }

  // console.log(args)
  // console.log(options.center)
  // console.log(chart.config.options.plugins.doughnutlabel)
  // if (chart.config.options.plugins.doughnutlabel) {
  //   const { width, height, ctx } = chart
  //   //Get ctx from string
  //
  //   //Get options from the center object in options
  //   var config = chart.config.options.plugins.doughnutlabel
  //   var fontStyle = config.fontStyle || 'Arial'
  //   var txt = config.text
  //   var color = config.color || '#000'
  //   var sidePadding = config.sidePadding || 20
  //   var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
  //   //Start with a base font of 30px
  //   ctx.font = '30px ' + fontStyle
  //
  //   //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
  //   var stringWidth = ctx.measureText(txt).width
  //   var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated
  //
  //   // Find out how much the font can grow in width.
  //   var widthRatio = elementWidth / stringWidth
  //   var newFontSize = Math.floor(30 * widthRatio)
  //   var elementHeight = chart.innerRadius * 2
  //
  //   // Pick a new font size so it will not be larger than the height of label.
  //   var fontSizeToUse = Math.min(newFontSize, elementHeight)
  //
  //   //Set font settings to draw it correctly.
  //   ctx.textAlign = 'center'
  //   ctx.textBaseline = 'middle'
  //   var centerX = (chart.chartArea.left + chart.chartArea.right) / 2
  //   var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
  //   ctx.font = `${fontSizeToUse}px ${fontStyle}`
  //   ctx.fillStyle = color
  //
  //   //Draw text in center
  //   ctx.fillText(txt, centerX, centerY)
  // }
  // },
}

export { doughnutlabel as default }
