<template>
  <div>
    <sub-navigation @change-sub-nav="changeSubNav" />
    <component :is="subPage" :site-id="selectedSite.id" :site-info="selectedSite.info"></component>
  </div>
</template>

<style scoped lang="scss">
.row-chart {
  .v-card__title {
    height: 80px;
  }
  .month-select {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 1000;
    max-width: 110px;
  }
}
</style>

<script>
import {
  mdiSolarPower,
  mdiSolarPanel,
  mdiLightningBolt,
  mdiTrendingUp,
  mdiCarBattery,
  mdiNaturePeople,
  mdiSunWirelessOutline,
  mdiCashMultiple,
} from '@mdi/js'

import {
  ref, computed, onMounted, reactive,
} from '@vue/composition-api'
import SubNavigation from './SubNavigation.vue'
import Overview from './Overview.vue'
import Analysis from './Analysis.vue'
import Alarm from './Alarm.vue'
import Simulation from './simulation/Simulation.vue'
import store from '@/store'

export default {
  components: {
    Overview,
    Analysis,
    Alarm,
    Simulation,
    SubNavigation,
  },
  setup() {
    const selectedSite = reactive({
      id: null,
      info: {},
    })
    const selectSubPage = ref(1)
    const changeSubNav = event => {
      selectSubPage.value = event.value
    }
    const subPage = computed(() => {
      if (selectSubPage.value === 2) {
        return Analysis
      }
      if (selectSubPage.value === 3) {
        return Alarm
      }
      if (selectSubPage.value === 4) {
        return Simulation
      }

      return Overview
    })

    store.watch(state => state.sites.selected, (newValue, oldValue) => {
      // console.log(`Updating from ${oldValue} to ${newValue}`)
      selectedSite.id = store.getters['sites/siteId']
      selectedSite.info = store.state.sites.selected
      store.dispatch('devices/fetchDevices', { siteId: store.getters['sites/siteId'] })
    })

    onMounted(() => {
      store.dispatch('sites/fetchSites')
        .then(() => {
          selectedSite.id = store.getters['sites/siteId']
          selectedSite.info = store.state.sites.selected
        })
    })

    return {
      selectedSite,
      subPage,
      changeSubNav,

      mdiSolarPower,
      mdiSolarPanel,
      mdiLightningBolt,
      mdiTrendingUp,
      mdiCarBattery,
      mdiNaturePeople,
      mdiSunWirelessOutline,
      mdiCashMultiple,
    }
  },
}
</script>
