<template>
  <div>
    <v-btn-toggle
      class="mb-3 d-flex justify-center"
      v-model="toggleMonth"
    >
      <v-btn
        v-for="(month, index) in months" :key="index"
        depressed
        small
        class="text-capitalize"
      >
        {{ month }}
      </v-btn>
    </v-btn-toggle>
    <bar-chart
      class="mb-6"
      :height="400"
      :options="chartOptions"
      :chart-data="chartData"
    />
  </div>
</template>

<script>
import {
  ref, computed, defineComponent,
} from '@vue/composition-api'
import { BarChart } from 'vue-chart-3'

import 'chartjs-adapter-dayjs'

export default defineComponent({
  name: 'PivotkWChart',
  components: {
    BarChart,
  },
  setup() {
    const toggleMonth = ref(0)
    const pivotSoc = ref([])
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const chartData = computed(() => ({
      labels: pivotSoc.value[toggleMonth.value]?.Time || [],
      datasets: [
        {
          type: 'line',
          label: 'SOC (%)',
          data: pivotSoc.value[toggleMonth.value]?.SOC.map(item => parseFloat(item.replace('%', ''))) || [],
          borderColor: 'rgb(72, 47, 146)',
          backgroundColor: 'rgb(72, 47, 146)',
          pointRadius: 0,
          order: 1,
        },
        {
          type: 'line',
          label: 'Load (kW)',
          data: pivotSoc.value[toggleMonth.value]?.Load_kW || [],
          fill: true,
          borderWidth: 0,
          pointRadius: 0,
          borderColor: 'rgba(0, 174, 239, 0.7)',
          backgroundColor: 'rgba(0, 174, 239, 0.7)',
          yAxisID: 'ykW',
          order: 4,
        },
        {
          type: 'line',
          label: 'Solar (kW)',
          data: pivotSoc.value[toggleMonth.value]?.Solar_kW || [],
          fill: true,
          borderWidth: 0,
          pointRadius: 0,
          borderColor: 'rgba(0, 180, 156, 0.7)',
          backgroundColor: 'rgba(0, 180, 156, 0.7)',
          yAxisID: 'ykW',
          order: 2,
        },
        {
          type: 'line',
          label: 'Generator (kW)',
          data: pivotSoc.value[toggleMonth.value]?.Gen_kW || [],
          fill: true,
          borderWidth: 0,
          pointRadius: 0,
          borderColor: 'rgba(218, 28, 92, 0.7)',
          backgroundColor: 'rgba(218, 28, 92, 0.7)',
          order: 2,
          yAxisID: 'ykW',
        },
      ],
    }))
    const chartOptions = computed(() => ({
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: true,
            text: '(%)',
          },
          labels: {
            formatter: value => `${value} %`,
          },
        },
        ykW: {
          position: 'right',
          title: {
            display: true,
            text: 'kW',
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
      },
    }))

    const resetData = () => {
      pivotSoc.value = []
    }

    const updateData = data => {
      resetData()
      pivotSoc.value = data
    }

    return {
      months,
      toggleMonth,
      chartData,
      chartOptions,

      updateData,
      resetData,
    }
  },
})
</script>
